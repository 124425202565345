<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div class="flex-1 bg-white pa-24 radius-20">

			<div class="under-line-bbb pb-16 flex-row">
				<h3 class="flex-1 size-px-20">{{  program.title }}</h3>
			</div>
			<!-- 커버등록 -->

			<div class="mt-30 ">

				<label for="cartelName" class="h5 color-333 flex-row justify-space-between items-center">웹 커버 등록 <span class="h6 size-px-12">PC 웹 전용 커버 이미지입니다.</span></label>
				<div class="mt-10 position-relative">
					<!-- 커버등록 -->
					<label class="box pa-5-10 radius-10 position-absolute size-px-12 bg-white-half" style="right: 10px; top: 10px; z-index: 999;">
						<v-icon small class="">mdi-camera</v-icon>
						커버 사진 등록
						<input_file type="file" class="fileform_hidden" accept="image/*" @change="setFile($event, 'cover')" ref="file" />
					</label>
					<div
						class="radius-20 flex-column justify-center items-center bg-eee box-cover"
						:style="'background-image: url(' + item_cartel.cartl_cover_img_url + '); background-size: cover; background-position: center center'"
					>
						<div
							v-if="!item_cartel.cartl_cover_img_url"
							class=" text-center size-px-12"
						>카르텔 커버를 등록하세요 <br/> <span class="color-gray size-px-11">가로 1200px 이상, 세로 300이상</span></div>
					</div>
				</div>
			</div>

			<div class="mt-30 flex-row justify-space-between">

				<div
					class="mr-30 flex-1"
				>
					<label for="cartelName" class="h5 color-333 flex-row justify-space-between items-center">로고 등록 <span class="h6 size-px-12">카르텔 로고 이미지입니다.</span></label>
					<div
						class="box-cover-profile flex-column justify-center items-center box mt-10 radius-20"
					>
						<label class="box-profile-outline">
							<span class="box-profile-inline">
								<v-icon class="color-white">mdi-camera-outline</v-icon>
							</span>
								<input_file type="file" class="fileform_hidden" accept="image/*" @change="setFile($event, 'profile')" ref="file" />
								<img
									v-if="item_cartel.cartl_profle_img_url"
									:src="item_cartel.cartl_profle_img_url"
								/>
						</label>
					</div>
				</div>

				<div
					style="width: 370px"
				>
					<label for="cartelName" class="h5 color-333 flex-row justify-space-between items-center">앱 커버 등록  <span class="h6 size-px-12">모바일 앱 전용 커버 이미지입니다.</span></label>

					<div
						class="box-cover-mobile bg-eee radius-20 mt-10 position-relative"
					>
						<!-- 커버등록 -->
						<label class="box pa-5-10 radius-10 position-absolute size-px-12 bg-white-half" style="right: 10px; top: 10px; z-index: 999;">
							<v-icon small class="">mdi-camera</v-icon>
							커버 사진 등록
							<input_file type="file" class="fileform_hidden" accept="image/*" @change="setFile($event, 'mobile')" ref="file" />
						</label>
						<div
							class=" flex-column justify-center items-center full-height "
							:style="'background-image: url(' + item_cartel.cartl_mobile_cover_img_url + '); background-size: cover; background-position: center center'"
						>
							<div
								v-if="!item_cartel.cartl_mobile_cover_img_url"
								class="text-center size-px-12"
							>
								<img :src="require('@/assets/image/cartel-cover-thumb.png')" /><br/><br/>
								카르텔 커버를 등록하세요 <br/> <span class="color-gray size-px-11">가로 750px 이상 세로 500px이상</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="mt-30">
				<div>
					<label for="cartelName" class="h5 color-333">카르텔 이름</label>
					<div
						class="radius-20 pa-10-20 flex-row items-center mt-16"
						style="border:1px solid #dadada"
					>
						<input
							v-model="item_cartel.cartl_name"
							type="text" id="cartelName" placeholder="카르텔 이름을 입력하세요." maxlength="20"
							class="flex-1"
						>
						<v-icon
							v-if="item_cartel.cartl_name"
							@click="item_cartel.cartl_name = ''"
							class="mr-10 color-dadada"
						>mdi-close-circle</v-icon>
						<span style="flex-basis: 40px" class="text-right color-bbb">{{ item_cartel.cartl_name.length }}/20</span>
					</div>
				</div>
				<div class="mt-20">
					<label for="cartelDesc" class="h5 color-333">카르텔설명</label>
					<div
						class="radius-20 pa-10-20 flex-row items-center mt-16"
						style="border:1px solid #dadada"
					>
						<input
							v-model="item_cartel.cartl_desctn"
							type="text" id="cartelDesc" placeholder="카르텔에 대한 설명을 해주세요." maxlength="40"
							class="flex-1"
						>
						<v-icon
							v-if="item_cartel.cartl_desctn"
							@click="item_cartel.cartl_desctn = ''"
							class="mr-10 color-dadada"
						>mdi-close-circle</v-icon>
						<!-- TODO : 텍스트 수 카운트 필요 -->
						<span style="flex-basis: 40px" class="text-right color-bbb">{{ item_cartel.cartl_desctn.length }}/40</span>
					</div>
				</div>
				<div class="mt-20">
					<label for="cartelDesc" class="h5 color-333">개인 SNS <button @click="snsCount"><v-icon class="color-primary">mdi-plus-circle</v-icon></button></label>
					<div
						v-for="i in sns_count"
						:key="'sns_' + i"
						class="radius-20 pa-10-20 flex-row items-center mt-16"
						style="border:1px solid #dadada"
					>
						<input
							v-model="item_cartel.member_social_url_list[i - 1].member_social_url"
							type="text" placeholder="개인 SNS 주소를 입력하세요." maxlength="40"
							class="flex-1"
						>

						<button
							v-show="i > 1"
							@click="removeSns(i - 1)"
						><v-icon>mdi-minus-circle</v-icon></button>
					</div>

				</div>

				<hr class="under-line mt-20">

				<div class="mt-20">
					<div class="flex-row justify-space-between items-center">
						<h5 class="color-333">자동 가입 승인</h5>
						<p class="toggle-group h_unset" @click="item_cartel.entry_approval_fg = !item_cartel.entry_approval_fg">
							<!-- TODO : 수신 허용시 .on 클래스 추가 -->
							<span class="btn-toggle" :class="{ on: item_cartel.entry_approval_fg}"><em class="hide">가입 승인</em></span>
						</p>
					</div>
					<div class="color-888 size-px-14">
						비활성화 시 개설자 또는 운영자의 승인을 통해서만 카르텔 가입이 가능합니다.
					</div>
				</div>

				<hr class="under-line mt-20">

				<div class="mt-20">
					<h5 class="color-333">카르텔 관심사를 선택하세요.</h5>
					<ul class="mt-16">
						<li
							v-for="(int, i_index) in items_interest"
							:key="'int_' + i_index"
							class="cursor-pointer mt-16"
						>
							<div class="bright_radio interest_area">

								<input
									v-model="item_cartel.concern_sphere_code"
									:value="int.code"
									type="radio"
									:id="int.code"
									style="display: flex;"
								>
								<label
									:for="int.code"
								>
									<strong class="inline-block size-px-16 font-weight-700 color-333"
										style="width:12%">{{ int.label }}</strong>
									<p class="inline-block size-px14 color-888">{{  int.text }}</p>
								</label>
							</div>
						</li>
					</ul>
				</div>

				<div class="top-line mt-20 pt-20 text-right">
					<button
						class="btn-inline btn-primary radius-20 pa-10-30"
						@click="postCartel"
					>개설 요청</button>
				</div>


			</div>
		</div>
	</div>
</template>
<script>
import MypageSide from "@/view/Mypage/mypage-lnb";
import input_file from "@/components/InputFile"
export default {
	name: 'CartelRequest'
	, components: {MypageSide, input_file}
	, props: ['callBack', 'user']
	, data: function(){
		return {
			program: {
				name: '카르텔 개설'
				, title: '카르텔 개설'
				, type: 'mypage'
				, not_footer: true
				, not_header: true
				, is_next: true
				, bg_contents: 'bg-gray01'
			}
			, item_cartel: {
				step: 1
				, cartl_name: ''
				, cartl_desctn: ''
				, entry_approval_fg: true
				, concern_sphere_code: ''
				, user_id: this.user.id
				, cartl_cover_img_url: ''
				, member_social_url_list: [
					{ member_social_url: '' }
				]
			}
			, cartel_img: ''
			, upload_file: ''
			, items_interest: [
				{ name: 'music', code: 'CM00400001', label: 'Music', text: '음악을 좋아하고 관심을 가지고 있습니다.'}
				, { name: 'art', code: 'CM00400002', label: 'Art', text: '예술, 디자인등에 관심을 가지고 있습니다.'}
				, { name: 'fashion', code: 'CM00400003', label: 'Fashion', text: '패션 디자인, 모델 등에 관심을 가지고 있습니다.'}
				, { name: 'influence', code: 'CM00400004', label: 'Influence', text: '유튜버, 블로거 등을 운영하는것에 관심을 가지고 있습니다.'}
			]
			, sns_count: 1
		}
	}
	, methods: {

		setFile: function(e, type){

			if(type == 'cover'){
				this.$set(this.item_cartel, 'cartl_cover_img_url', e[0])
			}else if(type == 'mobile') {
				this.$set(this.item_cartel, 'cartl_mobile_cover_img_url', e[0])
			}else{
				this.$set(this.item_cartel, 'cartl_profle_img_url', e[0])
			}
		}

		, postFile: async function(){
			return true
		}
		, postCartel: async  function(){
			this.$log.console('postCartel', this.item_cartel)

			if(!this.item_cartel.cartl_cover_img_url){
				this.$bus.$emit('notify', { type: 'error', message: '카르텔 이미지를 선택하세요'})
				return false
			}else if (!this.item_cartel.cartl_name || this.item_cartel.cartl_name == '') {
				this.$bus.$emit('notify', { type: 'error', message: '카르텔 이름을 입력하세요'})
				return false
			} else if (!this.item_cartel.cartl_desctn || this.item_cartel.cartl_desctn == '') {
				this.$bus.$emit('notify', { type: 'error', message: '카르텔 설명을 입력하세요'})
				return false
			} else if (!this.item_cartel.member_social_url_list[0].member_social_url || this.item_cartel.member_social_url_list[0].member_social_url == '') {
				this.$bus.$emit('notify', { type: 'error', message: '개인 SNS 주소를 입력하세요'})
				return false
			}else if (!this.item_cartel.concern_sphere_code) {
				this.$bus.$emit('notify', { type: 'error', message: '카르텔 관심사를 선택하세요'})
				return false
			}

			if(await this.postFile()){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_request
						, data: {
							member_number: this.user.member_number
							, cartl_name: this.item_cartel.cartl_name
							, cartl_desctn: this.item_cartel.cartl_desctn
							, entry_approval_fg: !this.item_cartel.entry_approval_fg ? 'N' : 'Y'
							, concern_sphere_code: this.item_cartel.concern_sphere_code
							, cartl_cover_img_url: this.item_cartel.cartl_cover_img_url
							, cartl_mobile_cover_img_url: this.item_cartel.cartl_mobile_cover_img_url
							, cartl_profle_img_url: this.item_cartel.cartl_profle_img_url
							, member_social_url_list: this.item_cartel.member_social_url_list
						}
						, name: 'postCartel'
						, type: true
					})

					if(result.success){
						this.$emit('to', { name: 'CartelRequestList'})
					}else{
						throw result.message
					}

				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}

		,goBack: function(){
			if(this.item_cartel.step == 1){
				this.$emit('goBack')
			}else{
				this.item_cartel.step = 1
			}
		}
		, snsCount: function(){
			if(this.sns_count < 5){
				this.item_cartel.member_social_url_list.push({
					member_social_url: ''
				})
				this.sns_count++
			}
		}
		, removeSns: function(i){
			this.item_cartel.member_social_url_list.splice(i, 1)
			this.sns_count--
		}
	}
	,created() {
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>

<style>
.cover_background { background-color: #eee}

/* ul li:nth-child(n+2){
	margin-top: 16px;
} */

.box-cover {
	height: 120px;
}

.box-cover-mobile {
	width: 370px;
	height: 250px;
}

.box-cover-profile {

	height: 250px;
}

.ma-camera {
	margin-top: -2px;
	margin-left: 1px
}

.box-profile-outline {
	position: relative;
	display: flex; flex-direction: column; align-items: center; justify-content: center;
	background-color: #2C2C2C; width: 95px; height: 95px;
	border-radius: 100%;
	overflow: hidden;
}

.box-profile-inline {
	position: absolute; left: calc(50% - 28px); top: calc(50% - 28px); z-index: 999;
	display: flex; flex-direction: column; align-items: center; justify-content: center;
	width: 56px; height: 56px;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 100%;
}
.box-profile-outline img {
	object-fit: cover;
}
</style>